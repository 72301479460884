import { ActionContext, Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyFunctions, bloqifyStorage, firebase } from '@/boot/firebase';
import { Counts, DataContainerStatus } from '@/models/Common';
import { Investor, isInvestor, KYCMethods, User, UserStatus, UserTier } from '@/models/users/User';
import { UserData } from '@/models/identification-requests/pescheck';
import {
  IdentificationRequest,
  IdentificationRequestStatus,
} from '@/models/identification-requests/IdentificationRequest';
import { Idin } from '@/models/identification-requests/idin';
import { QuestionnaireAnswer, QuestionnaireAnswers } from '@/models/users/Questionnaire';
import moment from 'moment';
import { clientConfig } from '@/helpers/clientData';
import { OppStatus } from '@/models/opp/Opp';
import { Investment } from '@/models/investments/Investment';
import { generateState, mutateState, Vertebra } from '../utils/skeleton';
import { generateFileMd5Hask } from '../utils/files';
import { FirebaseError } from 'firebase/app';

const SET_USER = 'SET_USER';

const whitelabelConfig = clientConfig();
const pescheckEnabled = whitelabelConfig.pescheckEnabled;

/*
 * unfortunately it is not possible to extend enums directly
 * thus we create an object combining the enums
 * but an object is not a type too in TS thus the type is declared explicitly
 * on import both are imported automatically
 */
enum StatusRest {
  Error = 'error',
  None = 'none',
}

export const GetUserIdentificationStatus = { ...IdentificationRequestStatus, ...StatusRest };
export type GetUserIdentificationStatus = IdentificationRequestStatus | StatusRest;
export interface SendQuestionnaireParam {
  questionnaireAnswers: QuestionnaireAnswers;
  userId: string;
}

export default {
  state: generateState(),
  mutations: {
    [SET_USER](
      state,
      { status, payload, operation }: { status: DataContainerStatus; payload?: unknown; operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async handleUserStatus(
      { commit }: ActionContext<Vertebra, State>,
      { id, status, statusMessage }: { id: string; status: User['status']; statusMessage?: User['statusMessage'] },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'handleUserStatus' });

      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const countsRef = bloqifyFirestore.collection('settings').doc('counts');
      const [transactionUpdateError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const documentRef = bloqifyFirestore.collection('investors').doc(id);
          const [readUser, readUserSuccess] = await to(transaction.get(documentRef));
          if (readUser || !readUserSuccess?.exists) {
            throw readUser || Error('Error getting the payment.');
          }

          transaction.update(documentRef, {
            status,
            ...(statusMessage && { statusMessage }),
            updatedDateTime: serverTimestamp,
          });

          const counter = status === UserStatus.Disabled ? -1 : 1;
          transaction.set(
            countsRef,
            {
              activeUsers: firebase.firestore.FieldValue.increment(counter),
              ...((readUserSuccess.get('tier') as User['tier']) === UserTier.Investor && {
                activeInvestors: firebase.firestore.FieldValue.increment(counter),
              }),
              updatedDateTime: serverTimestamp,
            } as Counts,
            { merge: true },
          );
        }),
      );

      if (transactionUpdateError) {
        commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: transactionUpdateError,
          operation: 'handleUserStatus',
        });
        return;
      }

      commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: { status },
        operation: 'handleUserStatus',
      });
    },
    async sendQuestionnaire(_: ActionContext<Vertebra, State>, data: SendQuestionnaireParam): Promise<void> {
      const storageUploads: firebase.storage.UploadTask[] = []; // collect the upload tasks in this array

      // todo don't upload files if already exists
      // store data if needed and transform to link
      const answersToStore: QuestionnaireAnswer[] = data.questionnaireAnswers.answers.map(
        (answer, index): QuestionnaireAnswer => {
          if (answer.type === 'file') {
            const file = answer.answer as unknown as File;
            const fileName = `investors/${data.userId}/questionnaire${index}.${file.name}`;
            storageUploads.push(bloqifyStorage.ref().child(fileName).put(file));

            return {
              answer: fileName,
              type: 'file',
            };
          }
          return answer;
        },
      );

      const [uploadAnswersError] = await to(Promise.all(storageUploads)); // upload files
      if (uploadAnswersError) {
        throw Error(uploadAnswersError.message);
      }

      const objToStore: QuestionnaireAnswers = {
        answers: answersToStore,
        createdDateTime: data.questionnaireAnswers.createdDateTime,
        questions: data.questionnaireAnswers.questions,
      };
      const [dbError] = await to(
        // write answers to firestore
        bloqifyFirestore.collection('investors').doc(data.userId).update({
          questionnaire: objToStore,
        }),
      );
      if (dbError) {
        throw Error(dbError.message);
      }
    },
    async createUser(
      { commit }: ActionContext<Vertebra, State>,
      data: { user: Investor & { password: string }; sendEmail: boolean },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'createUser' });

      const { user, sendEmail } = data;
      const { email, password, ...restOfInvestor } = user;

      const dateOfBirthNotFilledIn = !(user as Investor).dateOfBirth;
      const userData: UserData = {
        email,
        first_name: (user as Investor).name,
        last_name: (user as Investor).surname,
        watchlist_threshold: '95',
        ...(!dateOfBirthNotFilledIn && {
          watchlist_date_of_birth: moment((user as Investor).dateOfBirth.toDate()).format('YYYY-MM-DD'),
        }),
      };

      // // Checking mime from FILES
      // const filesPathsMime: File[] = [
      //   (restOfInvestor as BusinessInvestor).passport as unknown as File,
      //   (restOfInvestor as BusinessInvestor).passportSecond as unknown as File,
      //   (restOfInvestor as BusinessInvestor).kvkImage as unknown as File,
      // ].filter((fileItem): boolean => !!fileItem) as File[];

      // const [mimeError, mimeSuccess] = await to(validateMimeTypes(filesPathsMime, 'ALL'));
      // if (mimeError || mimeSuccess) {
      //   return commit(SET_USER, { status: DataContainerStatus.Error, payload: Error('Error MIME file.'), operation: 'createUser' });
      // }

      const [createFirebaseUser, createUserSuccess] = await to(
        bloqifyFunctions.httpsCallable('createUser')({
          email,
          password,
          sendEmail,
        }),
      );
      if (createFirebaseUser) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: createFirebaseUser,
          operation: 'createUser',
        });
      }

      const id = createUserSuccess?.data.uid;

      if (!id) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error('There was an error retrieving the user id.'),
          operation: 'createUser',
        });
      }

      const storageRef = bloqifyStorage.ref();
      const getExtension = (type: string): string => type.substring(type.lastIndexOf('/') + 1, type.length);
      const fileHandler = async (filePath: string): Promise<void> => {
        const file = restOfInvestor[filePath] as File;
        if (file) {
          const md5Hash = await generateFileMd5Hask(file, true);
          const path = `investors/${id}/${filePath}.${getExtension(file.type)}`;
          const fileRef = storageRef.child(path);
          restOfInvestor[filePath] = path;

          await fileRef.put(file, { customMetadata: { md5Hash } });
        }
      };

      try {
        await Promise.all([
          fileHandler('passport'),
          // TODO add a condition for this later once part of configs
          fileHandler('passportSecond'),
          fileHandler('kvkImage'),
        ]);
      } catch (e) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: e,
          operation: 'createUser',
        });
      }

      if (createUserSuccess) {
        const [createUserError] = await to(
          bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
            let pescheckRef;
            if (pescheckEnabled && !dateOfBirthNotFilledIn) {
              try {
                const [requestPescheckError, requestPescheckSuccess] = await to(
                  bloqifyFunctions.httpsCallable('requestPescheck')({
                    userData,
                  }),
                );
                if (requestPescheckError) {
                  throw requestPescheckError;
                }
                pescheckRef = bloqifyFirestore.collection('pescheckv3_data').doc(id);
                transaction.set(pescheckRef, {
                  initialRequest: requestPescheckSuccess.data.screeningData,
                  createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
                  updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
                });
              } catch (e) {
                console.error(`Failed while performing the pescheck: ${(e as Error).toString()}`);
              }
            }

            transaction.set(
              bloqifyFirestore.collection('investors').doc(id),
              {
                ...restOfInvestor,
                email,
                status: UserStatus.Enabled,
                deleted: false,
                idRequestStatus: IdentificationRequestStatus.Approved,
                ...(pescheckEnabled && pescheckRef && { pescheck: pescheckRef }),
                createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
                updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
              } as User,
              { merge: true },
            );
            transaction.set(
              bloqifyFirestore.collection('settings').doc('counts'),
              {
                activeInvestors: firebase.firestore.FieldValue.increment(1),
                updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
              } as Counts,
              { merge: true },
            );
          }),
        );
        if (createUserError) {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: createUserError,
            operation: 'createUser',
          });
        }
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: id,
        operation: 'createUser',
      });
    },
    /**
     * Update function that handles also the firebase auth email change.
     */
    async updateUser(
      { commit }: ActionContext<Vertebra, State>,
      user: (User | Investor) & {
        uid: string;
        kvk?: string;
        kvkImage?: firebase.firestore.DocumentReference;
        companyId?: string;
      },
      uploadFiles = true,
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'updateUser' });

      const { uid, ...restOfInvestor } = user;
      const userRef = bloqifyFirestore.collection('investors').doc(uid);
      const [getUserError, userSnapshot] = await to(userRef.get());
      const idRequestRef = bloqifyFirestore.collection('identificationRequests').doc(uid);

      // // Checking mime from FILES
      // const filesPathsMime: File[] = [
      //   (restOfInvestor as BusinessInvestor).passport as unknown as File,
      //   (restOfInvestor as BusinessInvestor).passportSecond as unknown as File,
      //   (restOfInvestor as BusinessInvestor).kvkImage as unknown as File,
      // ].filter((fileItem): boolean => !!fileItem) as File[];

      // const [mimeError, mimeSuccess] = await to(validateMimeTypes(filesPathsMime, 'ALL'));
      // if (mimeError || mimeSuccess) {
      //   return commit(SET_USER, { status: DataContainerStatus.Error, payload: Error('Error MIME file.'), operation: 'updateUser' });
      // }

      if (uploadFiles) {
        const storageRef = bloqifyStorage.ref();
        const getExtension = (type: string): string => type.substring(type.lastIndexOf('/') + 1, type.length);
        const fileHandler = async (filePath: string): Promise<(() => void) | firebase.storage.UploadTask> => {
          const file = restOfInvestor[filePath] as File;
          if (file) {
            const md5Hash = await generateFileMd5Hask(file, true);
            const path = `investors/${uid}/${filePath}.${getExtension(file.type)}`;
            const fileRef = storageRef.child(path);
            restOfInvestor[filePath] = path;
            return fileRef.put(file, { customMetadata: { md5Hash } });
          }
          return (): void => undefined;
        };

        // The comparison of the md5Hash could have been done here via JavaScript (customMetadata.md5Hash) but it's also possible via
        // Firestore rules. The only caveat is that the error handling is not good at all, we cannot identify
        // what kind of error we are getting from the rules, only no permission.
        try {
          await Promise.all([
            fileHandler('passport'),
            // Todo also add a condition here
            fileHandler('passportSecond'),
            fileHandler('kvkImage'),
          ]);
        } catch (e) {
          if ((e as FirebaseError).code === 'storage/unauthorized') {
            console.error(`${(e as FirebaseError).message} => Likely this file already exists`);
          } else {
            return commit(SET_USER, {
              status: DataContainerStatus.Error,
              payload: e,
              operation: 'updateUser',
            });
          }
        }
      }

      if (getUserError || !userSnapshot) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error('There was an error retrieving the user.'),
          operation: 'updateUser',
        });
      }
      if (user.email && userSnapshot.get('email') !== user.email) {
        const [updateUserError] = await to(
          bloqifyFunctions.httpsCallable('updateUserEmail')({
            uid,
            email: user.email,
          }),
        );

        if (updateUserError) {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: updateUserError,
            operation: 'updateUser',
          });
        }
      }

      const prevIdentifier = userSnapshot.get('identifier');

      // Delete business fields if switching from business to private
      if (
        userSnapshot.get('kycMethod') === KYCMethods.Business &&
        (restOfInvestor as Investor).kycMethod === KYCMethods.Private
      ) {
        delete restOfInvestor.kvk;
        delete restOfInvestor.companyId;
        delete restOfInvestor.kvkImage;
      }
      // Update user
      const [updateUserError] = await to(
        userRef.update({
          ...restOfInvestor,
          idRequestStatus: IdentificationRequestStatus.Approved,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        }),
      );
      if (updateUserError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: updateUserError,
          operation: 'updateUser',
        });
      }

      const [getIdRequestError, getIdRequestSuccess] = await to(idRequestRef.get());

      if (getIdRequestError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: getIdRequestError,
          operation: 'updateUser',
        });
      }

      if (getIdRequestSuccess?.exists) {
        // Set since we could be deleting business fields if switching from business to private
        await to(
          userRef.set({
            ...restOfInvestor,
            idRequestStatus: IdentificationRequestStatus.Approved,
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          }),
        );

        if (getIdRequestSuccess.get('status') === IdentificationRequestStatus.Initial) {
          const [updateIdRequest] = await to(
            getIdRequestSuccess.ref.update({
              status: IdentificationRequestStatus.Approved,
              updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
            }),
          );
          if (updateIdRequest) {
            return commit(SET_USER, {
              status: DataContainerStatus.Error,
              payload: updateIdRequest,
              operation: 'updateUser',
            });
          }

          const [updateCountsDoc] = await to(
            bloqifyFirestore
              .collection('settings')
              .doc('counts')
              .update({
                pendingIdentificationRequests: firebase.firestore.FieldValue.increment(-1),
                updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
              }),
          );

          if (updateCountsDoc) {
            return commit(SET_USER, {
              status: DataContainerStatus.Error,
              payload: updateCountsDoc,
              operation: 'updateUser',
            });
          }
        }
      }

      // Update investments identifier
      if (prevIdentifier !== user.identifier) {
        const [investmentsError, investmentsData] = await to(
          bloqifyFirestore
            .collection('investments')
            .where('deleted', '==', false)
            .where('investor', '==', userRef)
            .get(),
        );
        if (investmentsError) {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: investmentsError,
            operation: 'updateUser',
          });
        }

        const [investmentsUpdateError] = await to(
          Promise.all(
            investmentsData.docs.map(
              async (investmentDoc): Promise<void> =>
                investmentDoc.ref.update({ identifier: user.identifier } as Investment),
            ),
          ),
        );
        if (investmentsUpdateError) {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: investmentsUpdateError,
            operation: 'updateUser',
          });
        }
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: user,
        operation: 'updateUser',
      });
    },
    async deleteUser(
      { commit }: ActionContext<Vertebra, State>,
      user: (User | Investor) & { uid: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'deleteUser' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          // Collection references
          const userRef = bloqifyFirestore.collection('investors').doc(user.id);
          const idRequestRef = bloqifyFirestore.collection('identificationRequests').doc(user.id);
          const countsRef = bloqifyFirestore.collection('settings').doc('counts');

          const [getUserError, userSnapshot] = await to(transaction.get(userRef));
          if (getUserError || !userSnapshot?.exists) {
            throw getUserError || Error('There was an error retrieving the user.');
          }

          const [getIdRequestError, getIdRequestSuccess] = await to(transaction.get(idRequestRef));
          if (getIdRequestError) {
            throw getIdRequestError || Error('There was an error retrieving the user identification request.');
          }

          // Delete user
          transaction.update(userRef, {
            deleted: true,
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });

          // Execute only if investor has associated an Identification Request
          if (getIdRequestSuccess?.exists) {
            if (getIdRequestSuccess.get('status') === IdentificationRequestStatus.Initial) {
              transaction.update(countsRef, {
                pendingIdentificationRequests: firebase.firestore.FieldValue.increment(-1),
                updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
              });
            }
            transaction.update(idRequestRef, {
              deleted: true,
              updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
        }),
      );

      if (transactionError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'deleteUser',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: user,
        operation: 'deleteUser',
      });
    },
    async updateUserNotes(
      { commit }: ActionContext<Vertebra, State>,
      user: (User | Investor) & { uid: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'updateUserNotes' });

      const userRef = bloqifyFirestore.collection('investors').doc(user.uid);

      // The call to the Bloqify cloud function (Admin SDK) is only neccessary if the email changed
      const [getUserError, userSnapshot] = await to(userRef.get());
      if (getUserError || !userSnapshot) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error('There was an error retrieving the user.'),
          operation: 'updateUser',
        });
      }

      const [updateUserError] = await to(
        userRef.update({
          quickNotes: user.quickNotes,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        }),
      );

      if (updateUserError) {
        console.log('updateUserError ', updateUserError);
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: updateUserError,
          operation: 'updateUserNotes',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: user,
        operation: 'updateUserNotes',
      });
    },
    async verifyUserEmail({ commit }: ActionContext<Vertebra, State>, { uid }: { uid: string }): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'updateUserNotes' });

      const userRef = bloqifyFirestore.collection('investors').doc(uid);

      // The call to the Bloqify cloud function (Admin SDK) is only neccessary if the email changed
      const [getUserError, userSnapshot] = await to(userRef.get());
      if (getUserError || !userSnapshot) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error('There was an error retrieving the user.'),
          operation: 'verifyUserEmail',
        });
      }

      const [verifyUserEmailError] = await to(bloqifyFunctions.httpsCallable('verifyUserEmail')({ uid }));
      if (verifyUserEmailError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: verifyUserEmailError,
          operation: 'verifyUserEmail',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: uid,
        operation: 'verifyUserEmail',
      });
    },
    async isUserEmailVerified({ commit }: ActionContext<Vertebra, State>, { email }: { email: string }): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'getUserEmailVerified' });

      const [isUserEmailVerifiedError, isUserEmailVerifiedSuccess] = await to(
        bloqifyFunctions.httpsCallable('getUserEmailVerified')({ email }),
      );
      if (isUserEmailVerifiedError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: isUserEmailVerifiedError,
          operation: 'getUserEmailVerified',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: isUserEmailVerifiedSuccess?.data,
        operation: 'getUserEmailVerified',
      });
    },
    async createInvestorWalletId(
      { commit }: ActionContext<Vertebra, State>,
      { investorId }: { investorId: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'createInvestorWalletId' });

      const [createInvestorWalletError, createInvestorWallet] = await to(
        bloqifyFunctions.httpsCallable('createInvestorWalletId')({ investorId }),
      );
      if (createInvestorWalletError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: createInvestorWalletError,
          operation: 'createInvestorWalletId',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: createInvestorWallet.data,
        operation: 'createInvestorWalletId',
      });
    },
    async fetchOppStatus({ commit }: ActionContext<Vertebra, State>, merchant: string): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'fetchOppStatus' });

      const [getOppStatusError, getOppStatus] = await to(
        bloqifyFunctions.httpsCallable('getMerchantStatus')({ merchant }),
      );
      if (getOppStatusError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: getOppStatusError,
          operation: 'fetchOppStatus',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: getOppStatus.data as OppStatus,
        operation: 'fetchOppStatus',
      });
    },
  },
  getters: {
    getUserIdentificationStatus:
      (state, getters, { boundUser }): ((user: User | undefined) => GetUserIdentificationStatus) =>
      (user: User | undefined): GetUserIdentificationStatus => {
        const tempUser = user || boundUser;
        if (!tempUser) {
          return GetUserIdentificationStatus.None;
        }
        if (isInvestor(tempUser)) {
          return IdentificationRequestStatus.Approved;
        }

        // check if IDIN nor Ir then no ID request was made
        if (!(tempUser.idin || tempUser.identificationRequest)) {
          return GetUserIdentificationStatus.None;
        }

        // if IDIN we've got an error if the status is not Success
        if (tempUser.idin) {
          return (tempUser.idin as Idin).Transaction.status === 'Success'
            ? GetUserIdentificationStatus.Approved
            : GetUserIdentificationStatus.Error;
        }

        // if IR use that status
        if (tempUser.identificationRequest) {
          return (tempUser.identificationRequest as IdentificationRequest).status || GetUserIdentificationStatus.None;
        }

        return GetUserIdentificationStatus.Error;
      },
    getInvestorNameByCustomID:
      (): ((customId: string) => Promise<string>) =>
      async (customId: string): Promise<string> => {
        const [getInvestorsError, getInvestors] = await to(
          bloqifyFirestore
            .collection('investors')
            .where('customId', '==', Number(customId))
            .where('deleted', '==', false)
            .limit(1)
            .get(),
        );

        if (getInvestorsError || !getInvestors?.docs[0]) {
          return '-';
        }

        const investorFirstName = await getInvestors.docs[0].get('name');
        const investorSurname = await getInvestors.docs[0].get('surname');
        return `${investorFirstName} ${investorSurname}`;
      },
  },
} as Module<Vertebra, State>;
